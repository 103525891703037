import React, { useState } from 'react'
import Layout from '../components/common/Layout'
import Fade from 'react-reveal/Fade'
import CTA from '../components/common/CTA'
import FeaturedPosts from '../components/resources/FeaturedPosts'
import AllPosts from '../components/resources/AllPosts'

const ResourcesPage = ({ data }) => {
  const posts = [...data.posts.nodes]
  const featuredPosts = posts
    .filter(p => p.frontmatter.featured === true)
    .slice(0, 3)

  return (
    <Layout
      meta={{
        description:
          'Welcome to the official blog of Atlas, you go-to resource for all things GIS and web mapping. Learn about the latest features, tutorials, and more.',
        title: 'Atlas Blog',
        type: 'website'
      }}
      title={'Atlas Blog'}
    >
      <main>
        {/* <Fade up duration={1000} delay={0} distance="30px">
          <FeaturedPosts posts={featuredPosts} />
        </Fade> */}
        <Fade up duration={1000} delay={0} distance="30px">
          <AllPosts posts={posts} />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <CTA text="Ready to level up your map-making process?" />
        </Fade>
      </main>
    </Layout>
  )
}

export default ResourcesPage

export const pageQuery = graphql`
  query {
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/blog/" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          featuredImage
          featured
          date(formatString: "MMMM D, YYYY")
          topic
          author {
            name
            role
          }
          meta {
            title
            description
          }
        }
        fields {
          slug
        }
      }
    }
  }
`
